var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('border-list', {
    attrs: {
      "no-gutters": ""
    }
  }, [_vm._l(_vm.fixed, function (notification) {
    return _c('border-list-item', {
      key: notification._id,
      attrs: {
        "to": "".concat(_vm.$route.path, "/").concat(notification._id)
      }
    }, [_c('div', {
      staticClass: "d-flex align-center justify-space-between"
    }, [_c('p', {
      staticClass: "page-text text-truncate"
    }, [_vm._v(_vm._s(notification.subject))]), _c('span', {
      staticClass: "page-text text-no-wrap pl-8 mr-n8"
    }, [_vm._v(" " + _vm._s(notification.createdAt.toDate()) + " "), _c('v-icon', {
      attrs: {
        "size": "24"
      }
    }, [_vm._v("mdi-chevron-right")])], 1)])]);
  }), _vm._l(_vm.notifications, function (notification) {
    return _c('border-list-item', {
      key: notification._id,
      attrs: {
        "to": "".concat(_vm.$route.path, "/").concat(notification._id)
      }
    }, [_c('div', {
      staticClass: "d-flex align-center justify-space-between"
    }, [_c('p', {
      staticClass: "page-text text-truncate"
    }, [_vm._v(_vm._s(notification.subject))]), _c('span', {
      staticClass: "page-text text-no-wrap pl-8 mr-n8"
    }, [_vm._v(" " + _vm._s(notification.createdAt.toDate()) + " "), _c('v-icon', {
      attrs: {
        "size": "24"
      }
    }, [_vm._v("mdi-chevron-right")])], 1)])]);
  })], 2), _vm.hasMoreItems ? _c('div', {
    staticClass: "pt-20"
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page: _vm.page + 1
          })
        });
      }
    }
  }, [_vm._v("더 보기")])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }