<template>
    <div class="pagination-container">
        <a class="pagination pagination--backward" @click="input(1)">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 14 12" style="enable-background:new 0 0 14 12;" xml:space="preserve">
                <path class="st0" d="M6.2,6.4C6,6.2,6,5.8,6.2,5.6l5.6-5.5c0.2-0.2,0.6-0.2,0.8,0s0.2,0.6,0,0.8l-5.1,5l5.3,5.2c0.2,0.2,0.2,0.6,0,0.8c-0.2,0.2-0.6,0.2-0.8,0"/>
                <path class="st0" d="M0.2,6.4C0,6.2,0,5.8,0.2,5.6l5.6-5.5c0.2-0.2,0.6-0.2,0.8,0s0.2,0.6,0,0.8l-5.1,5l5.3,5.2c0.2,0.2,0.2,0.6,0,0.8C6.6,12,6.2,12,6,11.8"/>
            </svg>
        </a>
        <a class="pagination pagination--prev" @click="input(page - 1)">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 8 12" style="enable-background:new 0 0 8 12;" xml:space="preserve">
                <path class="st0" d="M0.2,6.4C0,6.2,0,5.8,0.2,5.6l5.6-5.5c0.2-0.2,0.6-0.2,0.8,0s0.2,0.6,0,0.8l-5.1,5l5.3,5.2c0.2,0.2,0.2,0.6,0,0.8C6.6,12,6.2,12,6,11.8"/>
            </svg>
        </a>
        <div v-for="i in [-2,-1,0,1,2]" v-if="visibility(page + i)">
            <a v-if="i == 0" class="pagination pagination--current">{{page}}</a>
            <a v-else @click="input(i + page)" class="pagination"> {{page + i}}</a>
        </div>
        <a class="pagination pagination--next" @click="input(page + 1)">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 8 12" style="enable-background:new 0 0 8 12;" xml:space="preserve">
               <path class="st0" d="M6.8,5.5C7,5.7,7,6.1,6.8,6.3l-5.6,5.5C1,12,0.6,12,0.4,11.8s-0.2-0.6,0-0.8l5.1-5L0.2,0.8C0,0.6,0,0.2,0.2,0C0.4-0.1,0.8-0.1,1,0.1"/>
            </svg>
        </a>
        <a class="pagination pagination--forward" @click="input(count)">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 14 12" style="enable-background:new 0 0 14 12;" xml:space="preserve">
                <path class="st0" d="M2,11.8c-0.2,0.2-0.6,0.2-0.8,0c-0.2-0.2-0.2-0.6,0-0.8l5.3-5.2l-5.1-5c-0.2-0.2-0.2-0.6,0-0.8s0.6-0.2,0.8,0l5.6,5.5c0.2,0.2,0.2,0.6,0,0.8"/>
                <path class="st0" d="M8,11.8c-0.2,0.2-0.6,0.2-0.8,0c-0.2-0.2-0.2-0.6,0-0.8l5.3-5.2l-5.1-5c-0.2-0.2-0.2-0.6,0-0.8s0.6-0.2,0.8,0l5.6,5.5c0.2,0.2,0.2,0.6,0,0.8"/>
            </svg>
        </a>
    </div>
</template>

<script>
export default {
    props : {
        count: { type: Number, default: 0 },
        value: { type: Number, default: 1 },
    },
    data() {
        return {
            page: this.$props.value
        }
    },
    methods : {
        input(page){
            if(0 < page && page <= this.count){
                this.page = page;
                this.$emit("input", page);
                this.$emit("change", page);
            }
        },
        visibility(page){
            if(page < 1) return false;
            else if(this.count < page) return false;
            else return true;
        }
    },
    watch: {
        value(){
            this.page = this.value;
        }
    }
}
</script>

<style lang="scss" scoped>
/* board: Page Style */
.pagination-container{
    @at-root{
        [data-header-type="shop"] + .main .pagination-container{
            .pagination{
                 &--current { color: var(--v-primary-base); }
            }
        }
    }
    display: flex; justify-content: center; margin-top: 80px; margin-right: -2px; margin-left: -2px;
    .pagination{
        display: inline-flex; align-items: center; justify-content: center; position: relative; margin: 0 2px; width: 32px; height: 32px;
        text-align: center; font-size: 1.4rem; color: #bbb; font-weight: 400;
        border: 1px solid transparent; border-radius: 4px; cursor: pointer;
        svg { width: auto; height: 10px; fill: #6E6E6E; }
        &--current { z-index: 2; color: #222; font-weight: 700; }
        &--backward, &--forward,
        &--prev, &--next{
            border-color: #eee;
        }
    }
}
.pagination-container--block{
    .pagination.current { color: #ffffff; background-color: var(--v-primary-base); border-color: var(--v-primary-base); }
}
@media (min-width:1025px){
    .pagination-container{
        @at-root{
            [data-header-type="shop"] + .main .pagination-container{
                .pagination:not(.current):hover { border-color: var(--v-primary-base); }
                .pagination:not(.current):hover svg { fill: var(--v-primary-base); }
            }
        }
        .pagination:not(.current):hover { border-color: var(--v-primary-base); }
        .pagination:not(.current):hover svg { fill: var(--v-primary-base); }
    }
}
@media(max-width:1024px){
	.pagination-container{
        margin-top: 24px;
        .pagination { font-size: 14px; }
    }
}
@media (max-width:576px){
    .pagination-container{
        margin-top: 24px;
        .pagination {
            width:30px;
            height: 30px;
        }
    }
}
</style>