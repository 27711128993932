<template>
    <div class="mb-20">
        <v-tabs fixed-tabs>
            <v-tab to="/center/notice">공지사항</v-tab>
            <v-tab to="/center/faqs">FAQs</v-tab>
        </v-tabs>
        <v-divider/>
    </div>
</template>

<script>
export default {};
</script>

<style></style>
