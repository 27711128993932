<template>
    <section class="page-section">
        <v-container v-if="this.$slots['default']">
            <slot />
        </v-container>
        <slot v-if="this.$slots['pageSectionImmersive']" name="pageSectionImmersive" />
    </section>
</template>

<script>
export default{
    props : {
    },
    components: {
    },
    data: () => {
        return {
        }
    },
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		}
	},
}
</script>

<style scoped>
.page-section{
    position:relative;
    padding: 32px 0;
    background-color: #fff;
    border-top: 8px solid var(--v-grey-lighten5);
}
.page-section--first{
    border-top: 0;
}
.page-section--sm{
    padding: 20px 0;
}
.page-section__bg{
    position: absolute;
    top:0;
    left:50%;
    -webkit-transform: translate(-50%,0); -ms-transform: translate(-50%,0); transform: translate(-50%,0);
    z-index: -1;
    display: block;
    width: 100vw;
    height: 100%;
    background-repeat: no-repeat;
    background-position:center;
    background-size: cover;
}
</style>