var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.notification ? _c('div', {
    staticClass: "board-container"
  }, [_c('table', {
    staticClass: "table-default"
  }, [_c('tr', [_c('th', [_vm._v("제목")]), _c('td', [_vm._v(_vm._s(_vm.notification.subject))])]), _vm._m(0), _c('tr', [_c('th', [_vm._v("작성날짜")]), _c('td', [_vm._v(_vm._s(_vm.notification.createdAt.toDate()))])]), _vm.notification.upload ? _c('tr', [_c('th', [_vm._v("첨부파일")]), _c('td', [_c('a', {
    staticClass: "text-decoration-underline",
    on: {
      "click": function click($event) {
        _vm.download(_vm.notification.upload, _vm.notification.upload.name.split('/').pop());
      }
    }
  }, [_vm._v(_vm._s(_vm.notification.upload.name.split("/").pop()))])])]) : _vm._e(), _c('tr', [_c('td', {
    staticClass: "table-default__content",
    attrs: {
      "colspan": "2"
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.notification.content.replace(/\n/g, '<br>'))
    }
  })])])]), _c('div', [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("리스트")])], 1)]) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('th', [_vm._v("작성자")]), _c('td', [_vm._v("관리자")])]);
}]

export { render, staticRenderFns }