var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "v-main--wrap grey lighten-5"
  }, [_c('v-main', {
    staticClass: "client-page"
  }, [_vm._t("default")], 2), _c('div', {
    staticClass: "btn-area",
    style: _vm.btnAreaStyle
  }, [_c('v-btn', _vm._b({
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showsScrollTop,
      expression: "showsScrollTop"
    }],
    attrs: {
      "color": "white"
    },
    on: {
      "click": _vm.scrollTop
    }
  }, 'v-btn', _vm.attrs_btn, false), [_c('v-icon', [_vm._v("mdi-chevron-up")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }