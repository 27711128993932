var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-col', _vm._b({
    attrs: {
      "cols": "12"
    }
  }, 'v-col', _vm.$attrs, false), [_c('v-divider', {
    staticClass: "border-list__top-divider grey darken-4"
  }), _c('v-card', _vm._b({
    class: {
      'px-8': true,
      'py-12': !_vm.dense,
      'py-4': _vm.dense
    },
    attrs: {
      "tile": "",
      "flat": ""
    }
  }, 'v-card', {
    to: _vm.to
  }, false), [_vm._t("default")], 2), _c('v-divider')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }