<template>
    <client-page>
        <center-tab-btns />

        <v-container>
            <faq-list></faq-list>
        </v-container>
    </client-page>
</template>

<script>
import FaqList from "@/components/client/center/faqs/faq-list.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import CenterTabBtns from "@/components/client/center/center-tab-btns.vue";
import { mapMutations } from 'vuex';
import { LEFT_NAV_MODES } from '@/store/ui-header';

export default {
    components: {
        FaqList,
        ClientPage,
        CenterTabBtns,
    },
    mounted() {
        this.setLeftNavMode(LEFT_NAV_MODES.MENU);
        this.setTitle("고객센터");
    },
    destroyed() {
        this.setLeftNavMode(LEFT_NAV_MODES.MENU);
        this.setTitle();
    },
    methods: {
        ...mapMutations("ui", {
            setTitle: "header/setTitle",
            setLeftNavMode: "header/setLeftNavMode",
        }),
    },
};
</script>
