var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('v-expansion-panels', {
    staticClass: "board-list--faq",
    attrs: {
      "accordion": ""
    }
  }, _vm._l(_vm.faqs, function (faq, index) {
    return _c('v-expansion-panel', {
      key: index
    }, [_c('v-expansion-panel-header', [faq.type ? _c('p', {
      staticClass: "board-list--faq__category"
    }, [_vm._v(_vm._s(faq.type))]) : _vm._e(), _c('p', {
      staticClass: "board-list--faq__tit"
    }, [_vm._v(_vm._s(faq.subject))])]), _c('v-expansion-panel-content', [_c('p', {
      staticClass: "board-list--faq__txt",
      domProps: {
        "innerHTML": _vm._s(faq.content.replace(/\n/g, '<br>'))
      }
    })])], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }