var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-20"
  }, [_c('v-tabs', {
    attrs: {
      "fixed-tabs": ""
    }
  }, [_c('v-tab', {
    attrs: {
      "to": "/center/notice"
    }
  }, [_vm._v("공지사항")]), _c('v-tab', {
    attrs: {
      "to": "/center/faqs"
    }
  }, [_vm._v("FAQs")])], 1), _c('v-divider')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }