<template>
    <div class="board-container">
        <border-list no-gutters>
            <border-list-item v-for="notification in fixed" :key="notification._id" :to="`${$route.path}/${notification._id}`">
                <div class="d-flex align-center justify-space-between">
                    <p class="page-text text-truncate">{{ notification.subject }}</p>
                    <span class="page-text text-no-wrap pl-8 mr-n8">
                        {{ notification.createdAt.toDate() }}
                        <v-icon size="24">mdi-chevron-right</v-icon>
                    </span>
                </div>
            </border-list-item>
            <border-list-item v-for="notification in notifications" :key="notification._id" :to="`${$route.path}/${notification._id}`">
                <div class="d-flex align-center justify-space-between">
                    <p class="page-text text-truncate">{{ notification.subject }}</p>
                    <span class="page-text text-no-wrap pl-8 mr-n8">
                        {{ notification.createdAt.toDate() }}
                        <v-icon size="24">mdi-chevron-right</v-icon>
                    </span>
                </div>
            </border-list-item>
        </border-list>

        <div class="pt-20" v-if="hasMoreItems">
            <v-btn block outlined color="primary" @click="$router.push({ query: { ...$route.query, page: page + 1 } })">더 보기</v-btn>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import BorderList from "@/components/client/list/border-list.vue";
import BorderListItem from "@/components/client/list/border-list-item.vue";
import PaginationComponent from "@/components/client/control/pagination-component.vue";

export default {
    components: {
        BorderList,
        BorderListItem,
        PaginationComponent,
    },
    props: {
        value: { type: String, default: null },
    },
    data: () => ({
        limit: 10,
        summary: { totalCount: 0 },

        fixed: [],
        notifications: [],
    }),
    computed: {
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit || 0;
        },
        headers() {
            let { page, skip, limit } = this;
            if (page > 1 && (this.notifications || []).length < 1) {
                skip = 0;
                limit *= page;
            }
            return { skip, limit };
        },
        params() {
            let { query } = this.$route;
            return { ...query, isNotice: false };
        },
        hasMoreItems() {
            return (this.notifications || []).length < this.summary.totalCount;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            var { notifications: fixed } = await api.v1.center.notifications.gets({
                headers: { limit: 0 },
                params: { isNotice: true },
            });
            this.fixed = fixed;

            await this.search();
        },
        async search() {
            let { headers, params } = this;
            var { summary, notifications } = await api.v1.center.notifications.gets({ headers, params });

            this.summary = summary;
            this.notifications = this.notifications.concat(notifications);
        },
    },
};
</script>
