var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first"
  }, [!_vm.$route.params._notification ? _c('notification-list') : _c('notification-view')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }