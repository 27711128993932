<template>
    <div class="v-main--wrap grey lighten-5">
        <v-main class="client-page">
            <slot />
        </v-main>
        <div class="btn-area" :style="btnAreaStyle">
            <v-btn v-show="showsScrollTop" v-bind="attrs_btn" color="white" @click="scrollTop">
                <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
import detect from "@/plugins/detect";

const attrs_btn = {
    absolute: true,
    bottom: true,
    right: true,
    fab: true,
    large: true,
    class: "btn-floating",
    elevation: 3,
};

export default {
    components: {
    },
    props: {
        showsScrollTop: { type: Boolean, defatul: false },
    },
    data: () => ({
        attrs_btn,
        isMobile: detect == "mobile",
    }),
    computed: {
        btnAreaStyle() {
            return this.isMobile ? "width: 100%;" : "width: calc(100vh / 2);";
        },
    },
    watch: {
        "$route.path"() {
            this.scrollTop();
        },
    },
    methods: {
        scrollTop() {
            document.getElementsByTagName("html")[0].scrollTo({ top: 0, behavior: "smooth" });
        },
    },
};
</script>

<style lang="scss" scoped>
.client-page {
    position: relative;
    &::-webkit-scrollbar {
        width: 0;
    }
}
.v-main--wrap {
    display: flex;
    flex: 1 0 auto;
    max-width: 100%;
    padding: 56px 0;
}
.v-application .main--white{
    background-color: #fff !important;
}
.btn-area {
    pointer-events: none;

    position: fixed;
    top: 0;
    height: 100vh;

    > .v-btn {
        pointer-events: auto;
    }
}
.btn-floating {
    box-shadow: var(--shadow);
    transform: translateY(-50%);
    margin-bottom: calc(48px + 16px);
}
</style>
