<template>
    <v-col v-bind="$attrs" cols="12">
        <v-divider class="border-list__top-divider grey darken-4" />
        <v-card tile flat v-bind="{ to }" :class="{ 'px-8': true, 'py-12': !dense, 'py-4': dense }">
            <slot />
        </v-card>
        <v-divider />
    </v-col>
</template>

<script>
export default {
    props: {
        to: { type: String, default: "" },
        dense: { type: Boolean, default: false },
    },
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
