var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "border-list"
  }, [_c('v-row', _vm._b({}, 'v-row', _vm.$attrs, false), [_vm._t("default")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }