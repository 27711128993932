import { render, staticRenderFns } from "./border-list-item.vue?vue&type=template&id=d272ee84&scoped=true&"
import script from "./border-list-item.vue?vue&type=script&lang=js&"
export * from "./border-list-item.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d272ee84",
  null
  
)

export default component.exports