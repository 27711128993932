<template>
    <client-page>
        <!-- <center-tab-btns /> -->

        <page-section class="page-section--first">
            <!-- 1. 게시판 리스트: 베이직 스킨 -->
            <notification-list v-if="!$route.params._notification"></notification-list>
            <notification-view v-else></notification-view>
            <!-- 2. 게시판 작성: 베이직 스킨 -->
            <!-- <board-basic-input></board-basic-input> -->
            <!-- 3. 게시판 뷰: 베이직 스킨 -->
            <!-- <board-basic-view></board-basic-view> -->
        </page-section>
    </client-page>
</template>

<script>
import { mapMutations } from "vuex";
import { LEFT_NAV_MODES } from "@/store/ui-header";

import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import NotificationList from "@/components/client/center/notifications/notification-list.vue";
import NotificationView from "@/components/client/center/notifications/notification-view.vue";
// import CenterTabBtns from "@/components/client/center/center-tab-btns.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        NotificationList,
        NotificationView,
        // CenterTabBtns,
    },
    mounted() {
        this.setLeftNavMode(LEFT_NAV_MODES.BACK);
        this.setTitle("공지사항");
    },
    destroyed() {
        this.setLeftNavMode(LEFT_NAV_MODES.MENU);
        this.setTitle();
    },
    methods: {
        ...mapMutations("ui", {
            setTitle: "header/setTitle",
            setLeftNavMode: "header/setLeftNavMode",
        }),
    },
};
</script>
